import '@splidejs/react-splide/css/core'
import './src/css/styles.scss'
import React, { useEffect } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

export const wrapPageElement = ({ element, props }) => {
  // console.log(props.location.pathname)
  if (
    props.location.pathname === '/' ||
    props.location.pathname === '/alt' ||
    props.location.pathname === '/alt/'
  ) {
    return <ParallaxProvider>{element}</ParallaxProvider>
  } else {
    return element
  }
}

// Add additional var to the window
export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  if (window.locations[locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href)
  }
  const previousPath = window.locations[locations.length - 2]

  window.previousPath = previousPath !== undefined ? previousPath : ''
}
